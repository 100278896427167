.my_message {
  background-color: white;
  color: black;
  padding: 10px;
  margin: 10px;
  width: fit-content;
  border-radius: 15px;
  float: right;
}
.not_my_message {
  background-color: black;
  color: white;
  padding: 10px;
  margin: 10px;
  width: fit-content;
  border-radius: 15px;
}
