* {
  margin: 0;
  padding: 0;
}

.dark {
  background-color: rgb(49, 49, 49);
  color: white;
  height: 100vh;

  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.lite {
  background-color: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
  height: 100vh;

  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.main_canvas {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
.test_page_button {
  margin: 0.5vw;
  padding: 1vh;

  padding-left: 20px;
  padding-right: 20px;

  background-color: rgba(133, 133, 133, 0.283);
  border-radius: 25px;
}
.test_page_button_ary {
  display: flex;
  align-items: center;
  justify-content: center;
}
.right_answer {
  text-align: center;
}
