#pretty_css_disabled_text_selection {
  background-color: rgb(55, 55, 55);
  color: rgb(230, 219, 219);
  height: 75vh;

  display: flex;
  justify-content: center;
  align-items: center;
  
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
#prettycss_body_dark {
  background-color: rgb(29, 29, 29);
  color: white;
}
#prettycss_body_lite {
  background-color: white;
  color: black;
}

#pretty_css_grid_1 {
  background-color: aliceblue;
  color: black;
}
#pretty_css_grid_2 {
  background-color: darkblue;
}
#pretty_css_grid_3 {
  background-color: rgb(135, 132, 109);
  color: rgb(255, 255, 255);
}
#pretty_css_grid_4 {
  background-color: pink;
  color: black;
}
#pretty_css_grid_5 {
  background-color: rgb(98, 94, 59);
  color: rgb(255, 255, 255);
}
#pretty_css_grid_6 {
  background-color: rgb(23, 133, 93);
  color: rgb(95, 95, 95);
}

.pretty_css_main_card {
  display: flex;
  justify-content: center;

  text-align: center;
}
.pretty_css_center_card {
  width: 100%;
  max-width: 1130px;
  /* display: grid;
  grid-template-columns: 1fr 1fr; */
}
.pretty_css_text_card {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 75vh;
}
.pretty_css_grid {
  /* background-color: black; */
  color: rgb(230, 219, 219);
  height: 75vh;
  display: grid;
  grid-template-columns: 1fr auto 4fr;
}
#pretty_css_center_blue {
  background-color: blue;
  color: rgb(230, 219, 219);
  height: 75vh;

  display: flex;
  justify-content: center;
  align-items: center;
}
#pretty_css_center_red {
  background-color: red;
  height: 100px;
  width: 100px;
}

.flsdhfhj {
  padding-bottom: 50px;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

@media screen and (max-width: 800px) {
  .flsdhfhj {
    display: grid;
    grid-template-columns: 1fr;
    /* background-color: red; */
  }

  .pretty_css_text_card {
    height: 45vh;
  }
  .pretty_css_grid {
    height: 45vh;
  }
  #pretty_css_center_blue {
    height: 45vh;
  }
}

#prettycss_header {
  text-align: center;
  /* background-color: red; */
  padding: 30px;
}

#prettycss_cute_dog {
  /* height: 80vh;
  width: 84%; */
  /* background-color: rgb(17, 255, 108); */
  background-image: url("https://hips.hearstapps.com/hmg-prod/images/cutest-dog-breed-bernese-64356a43dbcc5.jpg");
  height: 75vh;
  width: 100%;

  background-repeat: no-repeat, no-repeat;
  background-position: 65%;
  background-size: cover;
  background-color: #cccccc;
  border-radius: 20px;
}

#prettycss_cute_dog_parnt {
  padding: 20px;
}
