#elizabot_mobile_historical_text_box {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr auto;
}
#elizabot_mobile_historical_text_input {
  flex: auto;
  margin: 5px;
  margin-top: 6px;
  margin-bottom: 6px;
}
#elizabot_mobile_historical_text_input_button {
  margin: 5px;
  background-color: rgb(0, 0, 0);
  color: #ffffff;
  border-radius: 7px;
  padding: 9px;
  padding-top: 4px;
  padding-left: 16px;
  padding-right: 14px;

  display: flex;
  justify-content: center;
  align-items: center;

  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
