#con {
  background-color: rgb(57, 57, 57);
  color: white;
  height: 100vh;

  display: flex;
  flex-direction: column;
  align-items: center;
}

#inputt {
  flex: auto;
  width: 100%;
}

#log {
  overflow-y: auto;
  width: 100%;
  flex: 10000;
}
