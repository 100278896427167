#historical_description {
  text-align: left;
  padding: 30px;
}

#historical_bot_chat_spinner {
  background-color: rgb(0, 0, 0);
  color: rgb(255, 255, 255);
  width: fit-content;
  border-radius: 16px;
  border-bottom-left-radius: 0px;
  padding: 10px;
  margin: 10px;

  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */

  display: none;
}

#historical_spinner {
  width: 10px;
  height: 10px;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  animation: spin 0.5s linear infinite; /* Apply the spin animation */
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#elizabot_page_thime_dark {
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: rgb(29, 29, 29);
  color: rgb(255, 255, 255);
}
#elizabot_page_thime_lite {
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
}

#elizabot_desktop_main {
  width: 100%;
  max-width: 1130px;

  padding-left: 15px;
  padding-right: 15px;
}

.historical_chat_left_bot {
  background-color: greenyellow;
  padding: 10px;
  border-radius: 15px;
  margin-bottom: 12px;

  border-color: greenyellow;
  border-style: solid;

  color: rgb(37, 37, 37);
}

.historical_chat_left_bot_highelite {
  background-color: greenyellow;
  padding: 10px;
  border-radius: 15px;
  margin-bottom: 12px;

  border-color: red;
  border-style: solid;

  color: rgb(37, 37, 37);
}

#historical_bots_name_and_description {
  padding-top: 30px;
  text-align: center;
  height: 70vh;

  width: 100%;
  max-width: 1130px;
}
#historical_chat_main_box {
  width: 100%;
  max-width: 1130px;

  /* margin: 50px; */
  /* padding: 50px; */
  padding-bottom: 80px;
  /* background-color: rgb(217, 255, 0); */
}

#historical_chat {
  display: grid;
  grid-template-columns: 1fr 4fr;
  border-radius: 20px;
  background-color: rgba(209, 209, 209, 0.344);
  height: 70vh;
  /* margin: 50px;
  padding: 10px; */
}

#historical_chat_left {
  background-color: rgb(12, 77, 241);
  padding: 10px;

  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.item {
  background-color: #f0f0f0;
  padding: 20px;
  text-align: center;
}

#historical_text_box {
  width: 90%;
  display: grid;
  grid-template-columns: 1fr auto;
  /* background-color: red; */
}
#historical_text_input_button {
  background-color: rgb(0, 0, 0);
  color: #ffffff;
  border-radius: 7px;
  padding: 9px;
  padding-top: 4px;
  padding-left: 16px;
  padding-right: 14px;

  display: flex;
  justify-content: center;
  align-items: center;

  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.historical_bot_chat {
  background-color: rgb(0, 0, 0);
  color: rgb(255, 255, 255);
  width: fit-content;
  border-radius: 16px;
  border-bottom-left-radius: 0px;
  padding: 10px;
  margin: 10px;

  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.hfsjkdfh {
  display: flex;
  justify-content: flex-end;
}
.historical_me {
  background-color: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
  width: fit-content;
  border-radius: 16px;
  border-bottom-right-radius: 0px;
  padding: 10px;
  margin: 10px;

  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

#historical_chat_rith {
  display: flex;
  flex-direction: column;
  align-items: center;
}
#historical_chat_log {
  width: 100%;
  flex: 40; /* 7fr for the first item */
}
#historical_text_input {
  flex: auto; /* Automatic size for the second item */
  margin: 5px;
}
