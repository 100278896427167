#home_body_dark {
  background-color: rgb(29, 29, 29);
  color: white;
}
#home_body_lite {
  background-color: white;
  color: black;
}

* {
  margin: 0px;
  padding: 0px;
  font-family: "Helvetica", "Arial", sans-serif;
  word-wrap: break-word;
  overflow: scroll;
  font-size: large;
}

*::-webkit-scrollbar {
  display: none;
}
#hider_1 {
  text-align: center;
  max-width: 1130px;
  /* background-color: aqua; */
}

#home_copyright_text {
  margin: 5px;
  padding: 5px;
  max-width: 1130px;
}
#home_copyright {
  background-color: greenyellow;
  padding: 20px;
  width: fit-content;
  color: rgb(255, 0, 0);

  position: fixed;
  bottom: 0;
  left: 0;
}
#home_page_3 {
  height: 37vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: rgba(141, 141, 141, 0.342);
}

.bodyy {
  display: flex;
  justify-content: center;
  padding: 25px;
  /* margin: 25px; */
}
.maine_card_desktop {
  width: 100%;
  max-width: 1130px;

  display: grid;
  grid-template-columns: 1fr 1fr;
}
.maine_card_m {
  width: 100%;
  max-width: 1130px;

  display: none;
}

a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

a:active {
  text-decoration: none;
}

.left_card_dark {
  /* background-color: rgb(29, 29, 29); */
  color: white;

  /* background-color: red; */
  /* height: 65vh; */

  text-align: center;

  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
}
.left_card_lite {
  /* background-color: white; */
  color: black;

  /* background-color: red; */
  height: 65vh;

  text-align: center;

  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
}

.rite_card {
  background-color: blue;
  height: 65vh;
  border-radius: 25px;
}

#home_productivity_woman {
  height: 65vh;
  border-radius: 25px;
  background-image: url("https://www.greenhub.com.sg/wp-content/uploads/2019/11/4-ways-how-women-can-be-more-productive-in-virtual-offices-1080x675.jpg");
  background-repeat: no-repeat, no-repeat;
  background-position: 65%;
  background-size: cover;
  background-color: #cccccc;
  /* border-radius: 20px; */

  /* background-size: 2000px 2000px; */
}
#home_learn_japanese {
  height: 65vh;
  border-radius: 25px;
  background-image: url("https://www.state.gov/wp-content/uploads/2023/07/shutterstock_653793838v2-1024x718.jpg");
  background-repeat: no-repeat, no-repeat;
  background-position: 65%;
  background-size: cover;
  background-color: #cccccc;
}
#home_historical_chat_bot {
  height: 65vh;
  border-radius: 25px;
  background-image: url("https://sm.pcmag.com/t/pcmag_me/photo/default/detroit-become-humantm-20180525150643_fwc4.1920.jpg");
  background-repeat: no-repeat, no-repeat;
  background-position: 65%;
  background-size: cover;
  background-color: #cccccc;
}
#home_prettycss {
  height: 65vh;
  border-radius: 25px;
  background-image: url("https://public-files.gumroad.com/7ksunomchkzlf6n0i2y953aleg3w");

  background-repeat: no-repeat, no-repeat;
  background-position: 65%;
  background-size: cover;
  background-color: #cccccc;
}

.imgae {
  background-color: greenyellow;
  border-radius: 25px;

  height: 65vh;
  width: 100%;
}

@media screen and (min-width: 900px) {
  .left_card_dark {
    /* background-color: red; */
    height: 65vh;
  }
}

@media screen and (max-width: 900px) {
  .maine_card_desktop {
    display: none;
  }
  .maine_card_m {
    display: block;
  }

  .left_card_dark {
   padding-top: 15px;
   padding-bottom: 60px;
  }
}
