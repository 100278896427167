@media screen and (max-width: 800px) {
  #elizabotDesktop {
    display: none;
  }
}

@media screen and (min-width: 800px) {
  #elizabotMobile {
    display: none;
  }
}
